import React, { useState, useEffect } from 'react'
import CartTokenCard from './CartTokenCard'
import CartMockCard from './CartMockCard'
import CheckoutForm from './CheckoutForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Cart from '../../models/cart.model'
import CartToken from '../../models/cartToken.model'
import CartMockPack from '../../models/cartMockPack.model'
import ErrorMessage from '../Items/ErrorMessage'
import { CartBundle } from '../../models/cartBundle.model'
import CartBundleCard from './CartBundleCard'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
)

interface CartSectionProps {
  cartData: Cart
  onRemoveCartItem: (cartId: number) => void
}

const CartSection: React.FC<CartSectionProps> = ({
  cartData,
  onRemoveCartItem,
}) => {
  const [cartTokens, setCartTokens] = useState<CartToken[]>(cartData.cartToken)
  const [cartMockPacks, setCartMockPacks] = useState<CartMockPack[]>(
    cartData.cartMockPack,
  )
  const [cartBundle, setCartBundle] = useState<CartBundle[]>(
    cartData.cartBundle,
  )
  const [totalAmount, setTotalAmount] = useState<number>(0)

  // Calculate totalAmount when cartTokens or cartMockPacks changes
  useEffect(() => {
    const tokenTotal = cartTokens.reduce(
      (acc, token) => acc + parseFloat(token.product_final_price),
      0,
    )
    const mockPackTotal = cartMockPacks.reduce(
      (acc, mockPack) => acc + parseFloat(mockPack.product_final_price),
      0,
    )
    const bundleTotal = cartBundle.reduce(
      (acc, bundle) => acc + parseFloat(bundle.product_final_price),
      0,
    )

    setTotalAmount(tokenTotal + mockPackTotal + bundleTotal)
  }, [cartTokens, cartMockPacks, cartBundle])

  // Function to update the price of a token
  const handleUpdateTokenPrice = (id: number, newPrice: string) => {
    setCartTokens((prevTokens) =>
      prevTokens.map((token) =>
        token.id === id ? { ...token, product_final_price: newPrice } : token,
      ),
    )
  }

  const handleUpdateMockPackPrice = (id: number, newPrice: string) => {
    setCartMockPacks((pvevMockPacks) =>
      pvevMockPacks.map((mockPack) =>
        mockPack.id === id
          ? { ...mockPack, product_final_price: newPrice }
          : mockPack,
      ),
    )
  }

  const handleUpdateBundlePrice = (id: number, newPrice: string) => {
    setCartBundle((prevBundles) =>
      prevBundles.map((bundle) =>
        bundle.cart_id === id
          ? { ...bundle, product_final_price: newPrice }
          : bundle,
      ),
    )
  }

  const handleRemoveItem = (id: number): void => onRemoveCartItem(id)

  const renderMockPacks = () =>
    cartMockPacks.length > 0 ? (
      cartMockPacks.map((mock) => (
        <>
          <p className="text-h2">Mock test pack</p>
          <CartMockCard
            key={mock.id}
            mock={mock}
            onRemoveItem={handleRemoveItem}
            onUpdatePrice={handleUpdateMockPackPrice}
          />
        </>
      ))
    ) : (
      <></>
      // <ErrorMessage message="No items available..." />
    )

  const renderTokens = () => {
    return cartTokens.length > 0 ? (
      cartTokens.map((token) => (
        <>
          <p className="text-h2 mt-2">Tokens</p>
          <CartTokenCard
            key={token.id}
            token={token}
            onRemoveItem={handleRemoveItem}
            onUpdatePrice={handleUpdateTokenPrice}
          />
        </>
      ))
    ) : (
      <></>
      // <ErrorMessage message="No items available..." />
    )
  }

  const renderBundleCards = () => {
    return cartBundle.length > 0 ? (
      cartBundle.map((bundle) => (
        <>
          <p className="text-h2 mt-2">Bundles</p>
          <CartBundleCard
            key={bundle.cart_id}
            bundle={bundle}
            onRemoveItem={handleRemoveItem}
            onUpdatePrice={handleUpdateBundlePrice}
          />
        </>
      ))
    ) : (
      <></>
      // <ErrorMessage message="No items available..." />
    )
  }

  const shouldRenderCheckout =
    cartMockPacks.length > 0 || cartTokens.length > 0 || cartBundle.length > 0

  return (
    <div className="cart-section font-landingPrimary">
      <div className="cart-section__checkout lg:w-full w-full lg:min-h-[56rem] lg:mt-[2rem] mt-[0.4rem] flex flex-wrap lg:gap-[2rem] gap-4">
        <div className="cart-section__checkout__products lg:w-[35.125rem] w-full h-full lg:py-0 py-[2rem] flex flex-col gap-4">
          {renderMockPacks()}
          {renderTokens()}
          {renderBundleCards()}
        </div>
        {shouldRenderCheckout && (
          <div className="cart-section__checkout__form lg:w-[30rem] w-full h-full">
            <Elements stripe={stripePromise}>
              <CheckoutForm total={totalAmount.toFixed(2)} />
            </Elements>
          </div>
        )}
      </div>
    </div>
  )
}

export default CartSection

import User from '../../models/user.model'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { calculateDaysDifference } from '../../utils/utils'
import { setTargetScore } from '../../services/user.services'
import { toastError, toastSuccess } from '../../utils/utils'

interface SetTargetScoreProps {
  user: User | undefined
}

const SetTargetScoreSection: React.FC<SetTargetScoreProps> = ({ user }) => {
  const checkTartgetTestDate = (targetTestDate: string) => {
    const today = new Date()
    const month = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0')
    const year = today.getFullYear()
    const currentDate = `${year}-${month}-${day}`

    return targetTestDate !== '1899-11-30' ? targetTestDate : currentDate
  }

  const [daysToTest, setDaysToTest] = useState<number>(0)
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      targetScore: user?.target_score || 10,
      targettTestDate:
        user && user.target_test_date
          ? checkTartgetTestDate(user.target_test_date)
          : '',
    },
  })

  useEffect(() => {
    if (user) {
      setDaysToTest(calculateDaysDifference(user.target_test_date))
      reset({
        targetScore: user.target_score || 10,
        targettTestDate:
          user && user.target_test_date
            ? checkTartgetTestDate(user.target_test_date)
            : '',
      })
    }
  }, [user, reset])

  const onSubmit = async (data: any) => {
    if (user && user.id) {
      try {
        const response = await setTargetScore(
          user.id,
          data.targetScore,
          data.targettTestDate,
        )
        if (response.data.success) {
          setDaysToTest(calculateDaysDifference(data.targettTestDate))
          toastSuccess(response.data.message)
        } else {
          toastError(response.data.message)
        }
      } catch (error) {
        if (error instanceof Error) {
          toastError(error.message)
        } else {
          toastError('An unknown error occurred!')
        }
      }
    } else {
      toastError('Something wrong!')
    }
  }

  return (
    <div className="my-[1rem] bg-[#F0F7FF] mx-auto rounded-lg shadow-apply-code-section lg:p-0 py-[1rem] px-2 lg:max-w-[49.5rem] w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-auto lg:py-[2rem] py-[0.4rem] flex flex-col gap-2 lg:w-[23.438rem] w-full">
          <p className="text-h5m">Set target score</p>
          <div>
            <label htmlFor="target-score" className="text-bodym">
              Desire score
            </label>
            <input
              type="text"
              id="target-score"
              placeholder="Type your target score here"
              className="w-full h-[2.375rem] text-bodyr shadow-user-profile-input rounded-lg p-[7px] border border-1 border-[#0000000F]"
              {...register('targetScore', {
                required: 'Your target score cannot be empty!',
                valueAsNumber: true,
                min: {
                  value: 10,
                  message: 'The target score must be at least 10!',
                },
                max: {
                  value: 90,
                  message: 'The target score must not exceed 90!',
                },
                validate: (value) =>
                  !isNaN(value) || 'The target score must be a valid number!',
              })}
            />
            {errors.targetScore && (
              <div className="text-red-500 text-footnote mt-1">
                {errors.targetScore.message}
              </div>
            )}
          </div>
          <div>
            <label htmlFor="expected-date" className="text-bodym">
              Exam date
            </label>
            <input
              type="date"
              id="expected-date"
              placeholder="Select your expected date"
              className="w-full h-[2.375rem] text-bodyr shadow-user-profile-input rounded-lg p-[7px] border border-1 border-[#0000000F]"
              {...register('targettTestDate', {
                required: 'The target test date cannot be empty!',
                validate: (value) => {
                  const selectedDate = new Date(value)
                  const today = new Date()

                  // Resetting time to 00:00:00 for an accurate comparison of only the dates
                  today.setHours(0, 0, 0, 0)
                  selectedDate.setHours(0, 0, 0, 0)

                  return (
                    selectedDate.getTime() > today.getTime() ||
                    'The target test date must be a valid future date!'
                  )
                },
              })}
            />
            {errors.targettTestDate && (
              <div className="text-red-500 text-footnote mt-1">
                {errors.targettTestDate.message}
              </div>
            )}
          </div>
          <p className="text-bodyr text-blue-400">
            You have {daysToTest} day(s) left until exam date
          </p>
          <div className="mt-[1rem] flex justify-end w-full">
            <button
              type="submit"
              className="w-[9.25rem] h-[2.375rem] bg-info hover:bg-[#1890FF] rounded-lg flex justify-center items-center text-white text-bodyr"
            >
              Set your target score
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default SetTargetScoreSection

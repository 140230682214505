import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { useNavigate } from 'react-router-dom'
import Path from '../../routes/Path'
import arrowRightIcon from '../../images/Homepage/arrow-right.svg'
import TopTenStudents from '../../models/topTenStudents.model'
import ErrorMessage from '../Items/ErrorMessage'
import TrophyIcon from '../../images/Homepage/trophy.svg'
import { Mousewheel } from 'swiper/modules'
import { getPositionColor } from '../../utils/utils'

interface TopStudentListProps {
  topTenStudents: TopTenStudents[]
}

const TopStudentList: React.FC<TopStudentListProps> = ({ topTenStudents }) => {
  const navigate = useNavigate()

  return (
    <div
      className={`flex lg:flex-row flex-col xxl:gap-52 xl:gap-24 lg:gap-16 gap-8 bg-primary-6 text-white xl:pl-20 xl:pr-24 lg:pl-12 lg:pr-16 md:pt-[71px] pt-8 md:px-14 px-3 ${
        topTenStudents.length === 0 ? 'md:pb-[71px] pb-8' : topTenStudents.length < 5 ? 'md:pb-16 pb-8' : ''
      }`}
    >
      <div
        className={`xl:max-w-[376px] lg:max-w-[250px] ${
          !topTenStudents ? 'lg:mt-32 mb-6' : ''
        } space-y-6`}
      >
        <div>
          <h2 className="text-h2 font-bold">Our top students this month</h2>
          <p className="text-bodyr mt-2">
            Meet our outstanding PTE achievers from Australia! Here, you can
            explore the impressive scores of our students and learn how our
            customized programs have paved their way to success.
          </p>
        </div>

        <button
          onClick={() => navigate(Path.weeklyPrediction.path)}
          className="flex items-center gap-2 bg-info text-bodyr text-white rounded-xl px-5 py-3 hover:scale-105"
        >
          <img src={arrowRightIcon} alt="arrow-right" className="w-3 h-3" />
          Start Learning Now
        </button>
      </div>
      <div className="w-full bg-transparent rounded-lg space-y-6">
        <h4 className="text-h4m text-center text-white">Scoreboard</h4>
        {topTenStudents.length !== 0 ? (
          <Swiper
            modules={[Mousewheel]}
            spaceBetween={10}
            slidesPerView="auto"
            direction="vertical"
            mousewheel={true}
            className="md:max-h-[489px] max-h-[289px] !max-w-xl"
          >
            {topTenStudents
              .sort((a, b) => b.overallTotal - a.overallTotal)
              .map((student, index) => {
                const position = index + 1
                return (
                  <SwiperSlide key={student.name} className="!h-fit">
                    <div
                      className="flex items-center justify-between p-3 rounded-xl"
                      style={{ backgroundColor: getPositionColor(position) }}
                    >
                      <div className="flex items-center gap-4">
                        <p className="text-bodyb text-black">#{position}</p>
                        <img
                          src={student.image}
                          alt="avatar"
                          className="w-10 h-10"
                        />
                        <p className="font-bold text-gray-900">
                          {student.name}
                        </p>
                      </div>
                      <div className="flex gap-3 items-center">
                        <img
                          src={TrophyIcon}
                          alt="trophy"
                          className="w-7 h-7"
                        />
                        <p className="text-bodyr text-black">
                          {student.overallTotal} pts
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
          </Swiper>
        ) : (
          <div className="h-[4rem] flex justify-center items-center">
            <ErrorMessage message="There aren't any top students this month" />
          </div>
        )}
      </div>
    </div>
  )
}

export default TopStudentList

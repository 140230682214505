import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MobileCourseList from './MobileCourseList'
import DesktopCourseList from './DesktopCourseList'
import LoadingMessage from './Items/LoadingMessage'
import { practiceWeeklyPrediction } from '../services/practice.services'
import { useAuth } from '../providers/AuthProvider'
import CategoryPractice from '../models/categoryPractice.model'
import { getUserTokenDetails } from '../services/user.services'
import Path from '../routes/Path'
import { navigateUserToPlanPage } from '../utils/utils'

const freeCourseStatusIdList = [
  9, 11, 26, 16, 13, 14, 15, 12, 18, 19, 20, 21, 22, 23, 24,
]

const CourseList = () => {
  const { userProfile } = useAuth()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [isLoading, setIsLoading] = useState(true)
  const [categories, setCategories] = useState<CategoryPractice[]>([])

  const navigate = useNavigate()
  let navigateToPlanPage: boolean = false;

  useEffect(() => {
    const checkUserPlan = async () => {
      if (userProfile) {
        const userInfo = await getUserTokenDetails(userProfile.userId)
        if (navigateUserToPlanPage(userInfo.data)) {
          navigate(Path.planPages.main.path)
          navigateToPlanPage = true
        }
      }
    }
    checkUserPlan()

    function handleResize() {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [userProfile, navigate])

  useEffect(() => {
    if (userProfile && userProfile.userId) {
      try {
        practiceWeeklyPrediction(userProfile.userId).then((response) => {
          if (response.data.success) {
            setCategories(response.data.category)
          }
          setIsLoading(false)
        })
      } catch (error) {
        console.error(error)
      }
    } else {
      console.error(userProfile)
    }
  }, [userProfile])

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading course list..." />
      ) : isMobile ? (
        <MobileCourseList
          catgories={categories}
          freeCourseStatusIdList={freeCourseStatusIdList}
          navigateToPlanPage={navigateToPlanPage}
          />
        ) : (
          <DesktopCourseList
          catgories={categories}
          freeCourseStatusIdList={freeCourseStatusIdList}
          navigateToPlanPage={navigateToPlanPage}
        />
      )}
    </div>
  )
}
export default CourseList
